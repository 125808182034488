import React from 'react';

function AdminPanelLogin() {
  return (
    <div className='h-screen flex justify-center items-center'>
        <p>Admin Panel 😎</p>
    </div>
  );
}

export default AdminPanelLogin;
