import React from 'react';
import Form from '../components/AdminPanelLogin/Form';

function AdminPanelLogin() {
  return (
    <div>
      <Form />
    </div>
  );
}

export default AdminPanelLogin;
